.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Scrollbar Styling */
.scrollable-element {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

.scrollable-element::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.scrollable-element::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
  border-radius: 10px;
}

.scrollable-element::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 10px;
}

.scrollable-element::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}

/* START uPlot styling */
.u-hz .u-cursor-x {
  height: 100%;
  border-right: 1px solid #607d8b60 !important;
}

.u-hz .u-cursor-y {
  width: 100%;
  border-bottom: 1px solid #607d8b60 !important;
}

.u-cursor-pt {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  border: 0 solid;
  pointer-events: none;
  will-change: transform;
  background-clip: padding-box !important;
  background-color: rgb(7, 7, 7) !important;
  border: white 1px solid !important;
  box-shadow: rgba(150, 150, 150, 0.349) 0 0 0 5px !important;
}
/* END uPlot styling */
