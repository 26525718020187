@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inter", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --bg-primary: #ffffff;
  --bg-secondary: #f8f8f8;
  --bg-details: #dfdfdf;
  --text-primary: #111827;
  --text-secondary: #374151;
  --text-tertiary: #6b7280;
  --text-error: #c71f1f;
  --focus-primary: #3b82f6;
  --button-primary: #3b82f6;
  --button-hover: #2563eb;
  --text-button: #ffffff;
  --border: #d7dde5;
  --scrollbar-thumb: #888;
  --scrollbar-thumb-hover: #555;
  --scrollbar-track: #f0f0f0;
}

.dark {
  --bg-primary: #111827;
  --bg-secondary: #1f2937;
  --bg-details: #2c3b4e;
  --text-primary: #f9fafb;
  --text-secondary: #d1d5db;
  --text-tertiary: #9ca3af;
  --text-error: #962828;
  --focus-primary: #7094e2;
  --button-primary: #2563eb;
  --button-hover: #1d4ed8;
  --text-button: #ffffff;
  --border: #283547;
  --scrollbar-thumb: #6b7280;
  --scrollbar-thumb-hover: #374151;
  --scrollbar-track: #1f2937;
}
